import axios from "axios"
import {store} from "../store"

export const getUri = () => {

   // return 'https://apicotizador.spring.com.co'

   var url = window.location.host.split('.')

   let domain = (url[0].includes('localhost')) ? 'spring_quotes_api.test' : 'apicotizador.spring.com.co'

   return `https://${domain}`
}

export const getDomain = () => {
   var uri = getUri()
   
   return `${uri}/api`
}

/*
 * Instancia con el servicio principal del backend
 */
export const springQuotesApi = () => {
   const state = store.getState();
   const authToken = state.auth.token;
   
   axios.defaults.withCredentials = true

   return axios.create({
      baseURL: getDomain(),
      timeout: 30000,
      headers: {
         Authorization: `Bearer ${authToken}`,
         "Content-Type": "application/json; charset=UTF-8",
      }
   });
};

export default springQuotesApi;